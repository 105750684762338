<template>
  <div style="height: 100%; width: 100%">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="listLoading"
      :searchFields="searchFields"
      :autoBtnList="autoBtnList"
      :colSize="colSize"
      :total="total"
      :tableData="dataList"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :sortFields="sortFields"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="true"
      :showColumnSearch="true"
      :showSortable="false"
      :showSortSetting="false"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :optionColumn="optionColumn"
      :selectTableSummary="selectTableSummary"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @saveSortField="saveSortField"
      @getCheckedData="getCheckedData"
      @getTableData="queryList"
      @toDetailHandle="toDetailHandle"
      @orderTrackHandle="orderTrackHandle"
      @orderRemarkHandle="orderRemarkHandle"
    >
    </search-table>
    <el-dialog width="35%" title="批量修改" :visible.sync="batchEditVisible">
      <el-form ref="batchEditForm" :model="batchEditForm" :rules="batchEditRules" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="渣土类型:">
              <el-select style="width: 70%" size="small" v-model="batchEditForm.tlxId">
                <el-option v-for="item in tlxList" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="倒土方式:">
              <el-radio v-model="dtfs" label="1">自倒</el-radio>
              <el-radio v-model="dtfs" label="2">渣土场</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="渣土场:">
              <el-select :disabled="ztcDisabled" style="width: 70%" size="small" v-model="batchEditForm.ztcId">
                <el-option v-for="item in ztcList" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="车队:">
              <el-select style="width: 70%" size="small" v-model="batchEditForm.fleetId">
                <el-option v-for="item in fleetDataList" :label="item.name" :value="item.id" />
                <el-option label="黄土" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="修改原因:" prop="modifyReason">
              <el-input style="width: 70%" :rows="1" v-model="batchEditForm.modifyReason" type="textarea" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <span style="margin-left: 40px"
              >已勾选工单:<span style="color: #1890ff">{{ editCount }}</span></span
            >
          </el-col>
          <el-col :span="14"
            ><span
              >修改操作人:<span style="color: #1890ff">{{ operater }}</span></span
            ></el-col
          >
        </el-row>
      </el-form>
      <div slot="footer">
        <div slot="footer">
          <el-button size="small" @click="batchEditVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="submitBatchEdit">提 交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="32%" title="工单备注" :visible.sync="batchRemarkVisible">
      <el-form ref="batchEditForm" :model="batchEditForm" :rules="batchEditRules" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark" label-width="0px">
              <el-input maxlength="50" show-word-limit :rows="3" v-model="batchEditForm.remark" type="textarea" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <div slot="footer">
          <el-button size="small" @click="batchRemarkVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="submitBatchRemark">提 交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="orderDetailVisible" direction="rtl" size="80%">
      <div style="line-height: 40px; color: #000; font-weight: bold" slot="title">
        {{ detailTitle }}
        <div v-if="dataList.length > 1" style="float: right; margin-right: 50px">
          <el-button
            size="mini"
            :disabled="curentIndex === 0 || dataList.length === 1"
            plain
            icon="el-icon-arrow-left"
            @click="lastOrder(detailRow.id)"
            >上一单</el-button
          >
          <el-button size="mini" :disabled="curentIndex === dataList.length - 1 || dataList.length === 1" plain @click="nextOrder(detailRow.id)"
            >下一单<i class="el-icon-arrow-right el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <project-order-detail v-if="detailRow !== null" :detailRow="detailRow" />
    </el-drawer>
  </div>
</template>
<script>
import ProjectOrderDetail from './projectOrderDetail'
import { projectList } from '@/api/stt'
import { queryList, batchEdit, ztcQuery, getFleetList, getTlxList, orderDetail, dataExport } from '@/api/order'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/projectOrder/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'
import store from '../../store'
export default {
  components: {
    SearchTable,
    ProjectOrderDetail
  },
  mixins: [column],
  data: function () {
    return {
      total: 0,
      summary: {},
      projectName: '',
      projectId: '',
      orderDetailVisible: false,
      // 批量修改表单
      batchEditForm: {
        ids: '', //工单主键Ids,多个id用英文逗号分开
        tlxId: '', //土类型
        ztcId: '', //倒土方式
        fleetId: '', //车队id
        driverName: '',
        modifyReason: '', //修改原因
        remark: '' //备注
      },
      editCount: 0, //批量操作修改条数,
      operater: store.getters.realName + '(' + store.getters.phone + ')', //操作人
      batchEditRules: {
        // tlxId: [{ required: true, message: '请选择土类型', trigger: 'blur' }],
        // ztcId: [{ required: true, message: '请选择倒土方式', trigger: 'blur' }],
        // fleetId: [{ required: true, message: '请选择车队', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        modifyReason: [{ required: true, message: '请输入修改原因', trigger: 'blur' }]
      },
      batchRemarkForm: {
        orderNo: '', // 工单号
        projectId: '', //工地项目ID
        remark: '' //备注
      },
      batchEditVisible: false, //批量编辑弹窗显示
      batchRemarkVisible: false, // 批量备注
      dataList: [],
      listLoading: false,
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      formParams: {}, // 当前查询的参数
      optionColumn: {
        title: '操作',
        width: 180,
        fixed: 'right',
        btnList: [
          {
            title: '查看详情',
            fn: 'toDetailHandle'
          },
          {
            title: '查看轨迹',
            fn: 'orderTrackHandle'
          },
          {
            title: '备注',
            fn: 'orderRemarkHandle'
          }
        ]
      },
      detailRow: {},
      detailTitle: '',
      ztcList: [],
      ztcDisabled: true,
      dtfs: '1', //倒土方式
      tlxList: [], //土类型
      fleetDataList: [],
      lastBtnDisabled: false,
      nextBtnDisabled: false,
      curentIndex: 0, //当前切换到的单号索引
      path: '/worker/order/V2/findByPage' // 更新字段名显示接口路径
    }
  },
  watch: {
    dtfs: {
      handler(val, oldVal) {
        if (val === '1') {
          this.ztcDisabled = true
          this.ztcId = -1
        } else {
          this.ztcDisabled = false
        }
      }
    }
  },
  created() {
    // this.projectId = this.$route.query.id
    // this.projectName = this.$route.query.name
    this.getProjectList()
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  activated() {
    this.projectId = this.$route.query.id
    this.projectName = this.$route.query.name
    // this.getProjectList()
    this.queryList()
  },
  methods: {
     // 获取该老板所有的工地
    getProjectList() {
      projectList().then(res => {
        let arr = [{ value: '', label: '全部项目' }]
        res.data.forEach(item => {
          arr.push({ value: item.name, label: item.name })
        })
        this.bossProList = arr
      })
    },
    // 提交批量修改
    submitBatchEdit() {
      this.$refs.batchEditForm.validate(valid => {
        if (valid) {
          batchEdit(this.batchEditForm).then(res => {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.batchEditVisible = false
              this.resetBatchEditForm()
              this.queryList()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 批量编辑
    batchEditHandler() {
      let len = this.selectTableData.length
      if (len === 0) {
        this.$alert('请先选择需要修改的工单!', '提示', {
          confirmButtonText: '关闭',
          type: 'error'
        })
        return
      } else {
        let idList = []
        let projectIds = []
        
        this.selectTableData.forEach(item => {
          idList.push(item.id)
          projectIds.push(item.projectId)
        })
        this.editCount = idList.length
        this.batchEditForm.ids = idList.join(',')
        let pi = [...new Set(projectIds)]
        this.ztcQuery(pi[0])
        this.fleetList(pi[0])
        this.getTlxList(pi[0])
        this.batchEditVisible = true
      }
    },
    // 获取渣土场别表
    ztcQuery(projectId) {
      ztcQuery({ projectId: projectId, name: '' }).then(res => {
        this.ztcList = res.data
      })
    },
    fleetList(projectId) {
      getFleetList({ projectId: projectId, name: '' }).then(res => {
        this.fleetDataList = res.data
      })
    },
    getTlxList(projectId) {
      getTlxList({ projectId: projectId, name: '' }).then(res => {
        this.tlxList = res.data
      })
    },
    // 导出
    exportExcelHandler() {
      let params = Object.assign({},this.formParams)
      params['deleteFlag'] = "DELETED"
      dataExport(params).then(response => {
        if (!response) {
          return
        }
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', '工单.xls')
        document.body.appendChild(link)
        link.click()
      })
    },
    // 恢复工单
    recoverOrderHandler() {
      this.$store.commit('navBar/SET_ACTIVE_MENU_ID','146')
      this.$store.commit('navBar/ADD_VIEW', {
        id: '146',
        path: '/order/recover',
        title: '工单恢复',
        name: 'projectOrderRecover',
        hidden: true,
        icon: 'el-icon-chat-dot-square',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] }
      })
      this.$router.push({ path: '/order/recover' })
    },
    // 备注
    orderRemarkHandle(row) {
      if (row.id) {
        this.batchEditForm.ids = row.id
        this.batchRemarkVisible = true
      } else {
        let len = this.selectTableData.length
        if (len === 0) {
          this.$alert('请先选择需要备注的工单!', '提示', {
            confirmButtonText: '关闭',
            type: 'error'
          })
          return
        } else {
          let idList = []
          this.selectTableData.forEach(item => {
            idList.push(item.id)
          })
          this.batchEditForm.ids = idList.join(',')
          this.batchRemarkVisible = true
        }
      }
    },
    // 批量备注提交
    submitBatchRemark() {
      this.$refs.batchEditForm.validate(valid => {
        if (valid) {
          batchEdit(this.batchEditForm).then(res => {
            if (res.code === 200) {
              this.batchRemarkVisible = false
              this.$message.success('备注成功')
              this.resetBatchEditForm()
              this.queryList()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 查看轨迹
    orderTrackHandle(row) {
      this.$router.push({ path: '/layout/routing', query: { id: row.id } })
    },
    // 查看详情
    toDetailHandle(row) {
      let index = this.dataList.findIndex(item => {
        return item.id === row.id
      })
      this.curentIndex = index
      this.getOrderDetail(row.id)
    },
    // 上一单
    lastOrder(cid) {
      let index = this.dataList.findIndex(item => {
        return item.id === cid
      })
      this.curentIndex = index
      this.getOrderDetail(this.dataList[index - 1].id)
    },
    getOrderDetail(id) {
      orderDetail({ id: id }).then(res => {
        this.detailRow = res.data
        this.detailTitle = res.data.orderNo + '——详情'
        this.orderDetailVisible = true
      })
    },
    // 下一单
    nextOrder(cid) {
      let index = this.dataList.findIndex(item => {
        return item.id === cid
      })
      this.curentIndex = index
      this.getOrderDetail(this.dataList[index + 1].id)
    },
    // 查询
    async queryList(params) {
      if (params) {
        if ((!this.formParams.projectName||!params['projectName']) && this.$route.query.name) {
          params['projectName'] = this.$route.query.name
        }
        if ((!this.formParams.projectId||!params['projectId']) && this.$route.query.id) {
          params['projectId'] = this.$route.query.id
        }
        this.formParams = params
      } else {
        if ((!this.formParams.projectName||!params['projectName']) && this.$route.query.name) {
          this.formParams['projectName'] = this.$route.query.name
        }
        if ((!this.formParams.projectId||!params['projectId']) && this.$route.query.id) {
          this.formParams['projectId'] = this.$route.query.id
        }
        params = this.formParams
      }
      // params = isParamsNotNull(params)
      // if (params.startDate && params.startDate.length) {
      //   params.startStartDate = params.startDate[0]
      //   params.endStartDate = params.startDate[1]
      // }
      // if (params.dueDate && params.dueDate.length) {
      //   params.startDueDate = params.dueDate[0]
      //   params.endDueDate = params.dueDate[1]
      // }
      this.listLoading = true
      // this.baseColumns = config.baseColumn(this)
      const res = await queryList({ ...params })
      if (res.code === 0) {
        this.dataList = res.data.items
        this.total = res.data.total * 1
        this.tableSummary = res.data.summary
        // this.baseColumns = config.baseColumn(this)
       // this.searchFields = config.searchFields(this)
        this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        // if(res.data.tableSettingFields) {
          this.baseColumns.forEach(item=> {
             if(item.field==='projectName') {
              item.selectOptions = this.bossProList
             }
          })
        // }
        this.path = res.data.path || this.path
        this.colSize = res.data.colSize
        this.initConfig()
      }
      this.listLoading = false
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    getCheckedData(data) {
      this.selectTableData = data
    },
    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {},
    //重置表单
    resetBatchEditForm() {
      this.batchEditForm = {
        ids: '', //工单主键Ids,多个id用英文逗号分开
        tlxId: '', //土类型
        ztcId: '', //倒土方式
        fleetId: '', //车队id
        driverName: '',
        modifyReason: '', //修改原因
        remark: '' //备注
      }
    }
  }
}
</script>
<style scoped></style>
