<template>
  <div>
    <div style="margin: 0 20px 20px 20px">
      <div class="module-title">工单详情</div>
      <div class="row-content">
        <div class="left">
          <span>工单号:</span><span class="span-v">{{ detailRow.orderNo }}</span>
        </div>
        <div class="middle">
          <span>土方单位:</span><span class="span-v">{{ detailRow.companyName }}</span>
        </div>
        <div class="right">
          <span>车牌号:</span><span class="span-v">{{ detailRow.plateNumber }}</span>
        </div>
      </div>
      <div class="row-content">
        <div class="left">
          <span>车类型:</span><span class="span-v">{{ detailRow.carTypeName }}</span>
        </div>
        <div class="middle">
          <span>进场时间:</span><span class="span-v">{{ detailRow.inReleaserTime }}</span>
        </div>
        <div class="right">
          <span>出场时间:</span><span class="span-v">{{ detailRow.outReleaserTime }}</span>
        </div>
        
      </div>
      <!-- <div class="row-content">
        <div class="left"><span>总包单位:</span><span class="span-v">-</span></div>
        <div class="middle"><span>建设单位:</span><span class="span-v">-</span></div>
        <div class="right"><span>监理单位:</span><span class="span-v">-</span></div>
      </div> -->
      <div class="row-content">
        <div class="left">
          <span>工地:</span><span class="span-v">{{ detailRow.projectName }}</span>
        </div>
        <div class="middle">
          <span>司机:</span><span class="span-v">{{ detailRow.driverName }}</span>
        </div>
        <div class="right">
          <span>车斗大小:</span><span class="span-v">{{ detailRow.bodySize }}</span>
        </div>
      </div>
      <div class="row-content">
        <div class="left">
          <span>单价:</span><span class="span-v">{{ detailRow.price }}</span>
        </div>
        <div class="middle">
          <span>班次:</span><span class="span-v">{{ detailRow.workType | bcTypeFilter }}</span>
        </div>
        <div class="right">
          <span>倒土方式:</span><span class="span-v">{{ detailRow.ztcName }}</span>
          <span class="ye-color" v-if="detailRow.preZtcName||detailRow.preFleetName||detailRow.preTlxName ">(修改前:{{detailRow.preZtcName?detailRow.preZtcName:'-'}})</span>
        </div>
       
      </div>
      <div class="row-content">
        <div class="left">
          <span>渣土类型:</span><span class="span-v">{{ detailRow.tlxName }}</span>
          <span class="ye-color" v-if="detailRow.preZtcName||detailRow.preFleetName||detailRow.preTlxName ">(修改前:{{detailRow.preTlxName?detailRow.preTlxName:'-'}})</span>
        </div>
        <div class="middle">
          <!-- <span>挖机:</span><span class="span-v">-</span> -->
          <span>进场管理员:</span><span class="span-v">{{ detailRow.inReleaser }}</span>
        </div>
        <div class="right">
          <span>出场管理员:</span><span class="span-v">{{ detailRow.outReleaser }}</span>
        </div>
        
      </div>
      <div class="row-content">
        <div class="left">
          <span>车队名称:</span><span class="span-v">{{ detailRow.fleetName }}</span>
          <span class="ye-color" v-if="detailRow.preZtcName||detailRow.preFleetName||detailRow.preTlxName ">(修改前:{{detailRow.preFleetName?detailRow.preFleetName:'-'}})</span>
        </div>
        <div class="middle">
          <!-- <span>车队长:</span><span class="span-v">-</span> -->
          <span>是否临时车:</span><span class="span-v">否</span>
        </div>
        <div class="right">
          <span>出场车重:</span><span class="span-v">{{ detailRow.outWeight }}</span>
        </div>
       
      </div>
      <div class="row-content">
        <div class="left">
         <span>工单修改时间:</span><span class="span-v">{{ detailRow.updated }}</span>
          <!-- <span>进场车斗状态:</span><span class="span-v">{{ detailRow.inBodyStatus }}</span> -->
        </div>
        <div class="middle">
          <span>工单修改人:</span><span class="span-v">{{ detailRow.updater }}</span>
        </div>
        <div class="right">
          <span>异常原因:</span><span class="span-v">{{ detailRow.exception }}</span>
        </div>
      </div>
      <!-- <div class="row-content">
        <div class="left">
          <span>出场车斗状态:</span><span class="span-v">{{ detailRow.outBodyStatus }}</span>
        </div>
        <div class="middle">
          
        </div>
        <div class="right">
          
        </div>
      </div> -->
      <!-- <div class="row-content">
        <div class="left"><span>出场选项:</span><span class="span-v">-</span></div>
        <div class="middle">
          <span>异常原因:</span><span class="span-v">{{ detailRow.exception }}</span>
        </div>
        <div class="right"><span>补打次数:</span><span class="span-v">-</span></div>
      </div> -->
      <div class="row-content">
        <div class="left" style="width: 100%">
          <span>工单备注:</span><span class="span-v">{{ detailRow.remark }}</span>
        </div>
      </div>

      <div class="module-title">工单状态</div>
      <div class="row-content">
        <div class="left">
          <span>工单状态:</span
          ><span class="span-v"
            ><el-tag v-if="detailRow.status == 'OUT'" type="success">已出场</el-tag
            ><el-tag v-if="detailRow.status == 'NOT_OUT'" type="danger">未出场</el-tag></span
          >
        </div>
        <div class="middle"><span>删除状态:</span><span class="span-v">
          <el-tag v-if="detailRow.deleteFlag == 'NORMAL'" type="success">正常</el-tag
            ><el-tag v-if="detailRow.deleteFlag == 'DELETED'" type="danger">删除</el-tag>
        </span></div>
        <div class="right"><span>撤销删除时间:</span><span class="span-v">-</span></div>
      </div>
      <div class="row-content">
        <div class="left">
          <span>异常状态:</span
          ><span class="span-v"
            ><el-tag v-if="detailRow.isException !== 'TRUE'" type="success">正常</el-tag
            ><el-tag v-if="detailRow.isException === 'TRUE'" type="danger">异常</el-tag></span
          >
        </div>
        <div class="middle">
          <span>异常原因:</span><span class="span-v">{{ detailRow.exception }}</span>
        </div>
        <div class="right"><span>取消异常时间:</span><span class="span-v">-</span></div>
      </div>
      <div class="row-content">
        <div class="left">
          <span>工地结算状态:</span
          ><span class="ye-color"
            ><el-tag v-if="detailRow.workSettlementStatus === 'SETTLE'" type="success">已结算</el-tag
            ><el-tag v-if="detailRow.workSettlementStatus === 'NOT_SETTLE'" type="warning">未结算</el-tag></span
          >
        </div>
        <div class="middle">
          <span>工地结算时间:</span><span class="span-v">{{ detailRow.workSettlementTime }}</span>
        </div>
        <div class="right">
          <span>工地结算操作人:</span><span class="span-v">{{ detailRow.workerOperator }}</span>
        </div>
      </div>
      <div class="row-content">
        <div class="left" style="width: 100%">
          <span>工地结算对象:</span><span class="span-v">{{ detailRow.workerSettler }}</span>
        </div>
      </div>
      <div class="row-content">
        <div class="left">
          <span>渣土场结算状态:</span
          ><span class="ye-color"
            ><el-tag v-if="detailRow.muckSettlementStatus === 'SETTLE'" type="success">已结算</el-tag
            ><el-tag v-if="detailRow.muckSettlementStatus === 'NOT_SETTLE'" type="warning">未结算</el-tag></span
          >
        </div>
        <div class="middle">
          <span>渣土场结算时间:</span><span class="span-v">{{ detailRow.muckSettlementTime }}</span>
        </div>
        <div class="right">
          <span>渣土场结算操作人:</span><span class="span-v">{{ detailRow.muckOperator }}</span>
        </div>
      </div>
      <div class="row-content">
        <div class="left" style="width: 100%">
          <span>渣土场结算对象:</span><span class="span-v">{{ detailRow.muckSettler }}</span>
        </div>
      </div>
      <div class="module-title">修改记录</div>
      <vxe-table :data="detailRow.changeRecords" :align="'center'">
        <vxe-table-column field="updated"  title="修改时间" width="200"></vxe-table-column>
        <vxe-table-column field="data" title="修改记录"  >
          <template slot-scope="scope">
            <div class="updateContent" style="display:inline;word-wrap: break-word;" v-html="repalceData(scope.row.data)"></div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="modifyReason" title="修改原由" width="250"></vxe-table-column>
        <vxe-table-column field="updater" title="修改人" width="100"></vxe-table-column>
      </vxe-table>
      <div class="module-title">图片</div>
      <div style="display: flex">
        <div style="width: 24.5%">
          <div style="text-align: center; text-decoration: ">
            <span>入场车斗图片</span>
            <br />
            <viewer v-if="detailRow.inCarBodyImg">
              <img :src="detailRow.inCarBodyImg" class="img" />
            </viewer>
            <el-empty v-else :image-size="110" :description="'暂无图片'"></el-empty>
          </div>
        </div>
        <div style="width: 24.5%">
          <div style="text-align: center">
            <span>出场车斗图片</span>
            <br />
            <viewer v-if="detailRow.outCarBodyImg">
              <img class="img" :src="detailRow.outCarBodyImg" />
            </viewer>
            <el-empty v-else :image-size="110" :description="'暂无图片'"></el-empty>
          </div>
        </div>
        <div style="width: 24.5%">
          <div style="text-align: center">
            <span>入场车头照片</span>
            <br />
            <viewer v-if="detailRow.inCarHeaderImg">
              <img class="img" :src="detailRow.inCarHeaderImg" />
            </viewer>
            <el-empty v-else :image-size="110" :description="'暂无图片'"></el-empty>
          </div>
        </div>
        <div style="width: 24.5%">
          <div style="text-align: center">
            <span>出场车头照片</span>
            <br />
            <viewer v-if="detailRow.outCarHeaderImg">
              <img class="img" :src="detailRow.outCarHeaderImg" />
            </viewer>
            <el-empty v-else :image-size="110" :description="'暂无图片'"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  filters: {
    bcTypeFilter(status) {
      const statusMap = {
        DAY_WORK: '白班',
        NIGHT_WORK: '夜班',
        OFF_WORK: '白晚班'
      }
      return statusMap[status]
    }
  },
  props: {
    detailRow: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    repalceData(data) {
      return data
    }
  }
}
</script>
<style lang="scss" scoped>
.module-title {
  margin-top: 15px;
  margin-bottom: 15px;
  border-left: solid;
  border-left-width: 7px;
  color: rgb(29, 106, 208);
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
}
::v-deep .updateContent  p {
  display: inline;
}

.row-content {
  display: flex;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  color: rgb(72, 70, 70);
}
.left,
.right {
  margin-left: 10px;
  width: 280px;
}
.middle {
  flex: 1;
}
.ye-color {
  padding-left: 10px;
  color: rgb(236, 109, 12);
}
.span-v {
  padding-left: 10px;
}
.img {
  margin-top: 10px;
  width: 180px;
  border-radius: 4px;
  height: 180px;
}
::v-deep .el-drawer__wrapper {
  z-index: 2000;
}
::v-deep .viewer-container {
  z-index: 9999;
}
</style>
